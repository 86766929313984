<template>
  <div class="d-flex fs-2">
    <img
      :src="image"
      style="height: 3.5rem; width: 3.5rem; object-fit: cover"
      class="rounded mr-3"
    />

    <div>
      <router-link
        v-slot="{ href }"
        custom
        :to="{ name: 'center', params: { centerId: getSluggableEntitySlug(center.descriptions) } }"
      >
        <a
          :href="href"
          class="font-weight-semibold text-dark text-underline"
        >
          {{ getSluggableEntityName(center.descriptions) }}
        </a>
      </router-link>

      <div
        v-if="center.distance"
        class="d-flex align-items-center"
      >
        <i class="far fa-location-dot mr-2" />
        <span>{{ Math.round(center.distance / 100) / 10 }}km</span>
      </div>
      <div class="text-gray-text">{{ center.address.city ?? center.address.zone }}</div>

      {{ price }}
    </div>
  </div>
</template>

<script>
import SluggableEntityMixin from '../../mixins/SluggableEntityMixin';

const imagineFilter = (size, url) => {
  return `https://s3.eu-west-3.amazonaws.com/sport-finder.image/images/cache/${size}/${url}`;
};

export default {
  mixins: [SluggableEntityMixin],
  props: {
    center: { type: Object, required: true },
    price: { type: String, required: true },
  },
  computed: {
    image() {
      return this.center.imagine
        ? imagineFilter('search_card', this.center.imagine)
        : this.center.image;
    },
    buttonLabel() {
      return this.trans('general.hop');
    },
    buttonVariant() {
      return 'light';
    },
  },
};
</script>
